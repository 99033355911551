<template>
  <div class="membership-minting-wrap">
    <div class="membership-minting-back back-number-01"></div>
    <div class="membership-minting-back back-number-02"></div>
    <div class="membership-minting-back back-number-03"></div>
    <div class="membership-minting-back back-number-04"></div>
    <div class="membership-minting-content-wrap">
      <div class="membership-minting-image-wrap">
        <div class="membership-minting-image number-01"></div>
        <div class="membership-minting-image number-02"></div>
        <div class="membership-minting-image number-03"></div>
        <div class="membership-minting-image number-04"></div>
        <div class="membership-minting-image number-05"></div>
      </div>
      <div class="membership-minting-content">
        <div class="membership-minting-title">
          <h4>Welcome to Winex</h4>
          <h2>Membership<br />NFT Minting</h2>
        </div>
        <div class="input-minting-count">
          <div>구매수량</div>
          <div>
            <div class="plus-minus-input-wrap">
              <div class="btn minus-btn" @click="minusBtn"></div>
              <input type="number" class="winex-input" v-model="mintingCount" />
              <div class="btn plus-btn" @click="plusBtn"></div>
            </div>
          </div>
        </div>
        <div class="membership-minting-text-content-wrap">
          <h2>유의사항</h2>
          <ul>
            <li>· 멤버쉽의 혜택은 내 지갑으로 들어온 순간부터 적용됩니다.</li>
            <li>
              · 잔여 수량에 따라 신청하신 만큼 구매가 되지 않을 수 있습니다.
            </li>
            <li>· Gas 비용이 발생하니 matic을 소유하고 있어야 합니다.</li>
            <li>· 해당 Minting은 랜덤 배정됩니다.</li>
            <li>
              · 프리세일, 1차 퍼블릭세일, 2차 퍼블릭세일에는 구매당 20개
              한정이며 3차 퍼블릭세일에는 구매갯수제한이 없습니다.
            </li>
          </ul>
        </div>
        <button @click="purchase" class="winex-btn primary-btn minting-btn">
          MINTING
        </button>
        <AlertModal
          :title="'안내'"
          :content="submitStatusMessage"
          :pcWidth="400"
          :button-content="'확인'"
          :button-class="'primary-btn'"
          :confirm-click-btn="onCloseSubmitModal"
          :is-open="submitModalIsOpen"
          :is-info="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import getContractApi, { PurchaseStep, ServiceType } from '../../api/contract';
import AlertModal from '../../components/modals/AlertModal.vue';
export default {
  components: { AlertModal },
  name: 'MembershipMinting',
  created() {
    // Loading On
    this.$store.commit('loading/init',{title:'Minting 중 입니다.',content:'잠시만 기다려 주시면 Minting이 성공적으로 완료됩니다!'})
    // Loading Off
    this.$store.commit('loading/done')
    this.contract = getContractApi();
  },
  data() {
    return {
      mintingCount: 1,
      maxCount: 20, // 아마 서버에서 받아와야 함..
      submitStatusMessage: '',
      submitModalIsOpen: false,
    };
  },
  watch: {
    mintingCount: function() {
      if (this.mintingCount > this.maxCount) {
        this.mintingCount = this.maxCount;
      }
      if (this.mintingCount < 1) {
        this.mintingCount = 1;
      }
    },
  },
  methods: {
    plusBtn: function() {
      this.mintingCount++;
    },
    minusBtn: function() {
      this.mintingCount--;
    },
    onCloseSubmitModal: function() {
      this.submitModalIsOpen = false;
    },
    openSubmitModal: function(message) {
      this.submitStatusMessage = message;
      this.submitModalIsOpen = true;
    },
    purchase: async function() {
      this.$store.commit('loading/init',{title:'잔여수량 확인중.',content:'구매 가능한 잔여수량 확인중입니다!'})
        this.contract.getRemainMembershipNfts(() => {
      })
      .then(
        (result) => {
          if( result < 1 ) {
            this.openSubmitModal('잔여 수량이 없으니 다음 기회에 신청해주시길 바랍니다. 감사합니다.');
            this.$store.commit('loading/done')
          } else {
            this.contract
            .purchaseMembership(this.mintingCount, (statusMsg) => {
              switch (statusMsg) {
                case PurchaseStep.Start:
                  this.$store.commit('loading/init',{title:'Minting 중 입니다.',content:'구매를 시작합니다!'})
                  break;
                case PurchaseStep.RequrireSign:
                  this.$store.commit('loading/init',{title:'Minting 중 입니다.',content:'메타 마스크에서 서명이 필요합니다!'})
                  break;
                case PurchaseStep.CompleteSign:
                  this.$store.commit('loading/init',{title:'Minting 중 입니다.',content:'서명이 완료되었습니다!'})
                  break;
                case PurchaseStep.RequrirePurchase:
                  this.$store.commit('loading/init',{title:'Minting 중 입니다.',content:'메타 마스크에서 구매 동의 확인이 필요합니다.!'})
                  break;
              }
            })
            .then((result) => {
              // console.log(result.length);
              let msg = `멤버쉽 ${result.length}개 구매를 완료하였습니다.<br>(`;
              for (var value of result) {
                // console.log(value); 
                const [tokendId, collection] = value.split("/").reverse();
                const type = this.contract.getServiceType();
                if (type === ServiceType.Main || type == ServiceType.Alpha) {
                  msg += `<a href="https://polygonscan.com/token/${collection}?a=${tokendId}" target="_blank">#${tokendId}</a> `
                } else if (type === ServiceType.Testnet) {
                  msg += `<a href="https://mumbai.polygonscan.com/token/${collection}?a=${tokendId}" target="_blank">#${tokendId}</a> `
                } else {
                  msg += `<a href="" target="_blank">#${tokendId}</a> `
                }
              }
              msg +=`)`;
              // console.log(msg);
              this.$store.commit('loading/done')
              this.submitStatusMessage = msg;
              this.submitModalIsOpen = true;
            })
            .catch((e) => {
              this.$store.commit('loading/done');
              if (e.name === "UserFriendlyError" && e.message) {
                this.submitStatusMessage = e.message;
              } else {
                this.submitStatusMessage = '구매 실패하였습니다.';
              }
              this.submitModalIsOpen = true;
            });
          this.$store.commit('loading/init')
          }
        }
      )
    },
  },
};

</script>

<style scoped lang="scss">
@import '@/assets/scss/membership-minting.scss';
</style>
